import React, { ReactNode } from 'react';
import Header from './Header';
import Footer from './Footer';

const Wrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <main className="flex flex-1">{children}</main>
            <Footer />
        </div>
    );
};

export default Wrapper;
