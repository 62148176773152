import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

import "../styles/LoginPage.scss";

const LoginPage: React.FC = () => {
	const navigate = useNavigate();
	const { login, isAuthenticated, username: currentAuthuser } = useAuth();
	const [password, setPassword] = useState("");
	const [username, setUsername] = useState(currentAuthuser ?? "");

	useEffect(() => {
		if (isAuthenticated) {
			navigate(`/user/${username}`);
		}
	}, [isAuthenticated]);

	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		const {left: authError, right: user} = await login(username, password);
		if (authError) {
			console.error("login error");
			return;
		}
		navigate(`/user/${user}`);
	};

	const handleSignup = () => {
		navigate("/signup");
	};

	return (
		<div className="flex-1 flex flex-col items-center justify-center">
			<form className="flex flex-col align-center" onSubmit={handleLogin}>
				<label>Username:</label>
				<input
					className="form-input"
					type="text"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<label className="form-label">Password:</label>
				<input
					className="form-input"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<button className="form-button border-none cursor-pointer" type="submit">
					Login
				</button>
				<button className="form-button border-none cursor-pointer" type="button" onClick={handleSignup}>
					Signup
				</button>
			</form>
		</div>
	);
};

export default LoginPage;
