import React, { useState, useEffect, MouseEvent  } from "react";
import { useParams } from "react-router-dom";

import { useSocket } from "../SocketContext";
import { useRecorder } from "../RecorderContext";

import "../styles/VoiceRecordingPage.scss"; // Import CSS file for styling

const VoiceRecorderPage: React.FC = () => {
	const [audioUrl, setAudioUrl] = useState<string | null>(null);
	const [currentTranslation, setCurrentTranslation] = useState<string>("");
	const { sessionId } = useParams<{ sessionId: string }>();

	const socket = useSocket();
	const recorder = useRecorder({
		createRiffHeaders: true
	});
	useEffect(() => {
		socket.onConnect(() => {
			if (!sessionId) {
				console.error("no sessionId");
				return;
			}
			socket.joinAudio(sessionId);
			console.debug("connected")
		});
		socket.onDisconnect(() => console.debug("disconnected"));
		socket.onTranslation(response => setCurrentTranslation(oldState => oldState + "\n" + response));
		socket.onTestResponse(response => setCurrentTranslation(oldState => oldState + "\n" + response));
		socket.onError((origin, message) => console.error(`error from ${origin}: ${message}`));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket.onConnect, socket.onDisconnect, socket.onTranslation]);
	useEffect(() => {
		recorder.onStart(() => {
			setCurrentTranslation("");
			setAudioUrl(null);
		});
		recorder.onStop((audioChunks) => {
			const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
			const audioUrlBlob = URL.createObjectURL(audioBlob);
			setAudioUrl(audioUrlBlob);
		});
		recorder.onDataAvailable((event, riffHeaders) => {
			console.log("sending");
			const finalAudio = new Blob([riffHeaders, event.data]);
			socket.sendTranslation(finalAudio);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recorder.onStart, recorder.onDataAvailable, recorder.onStop]);

	const handleStartRecording = (ev: MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		recorder.startRecording();
	};

	const handleStopRecording = (ev: MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		recorder.stopRecording();
	};

	return (
		<div className="flex-1 flex flex-col items-center justify-center">
			<div className="flex justify-center">
				<button
					className="record-btn border-none cursor-pointer flex flex-col align-center"
					onClick={recorder.isRecording ? handleStopRecording : handleStartRecording}
				>
					{recorder.isRecording ? "Stop Recording" : "Start Recording"}
				</button>
			</div>
			<div>
					<h2>Translation</h2>
					<p className="whitespace-pre-line">{currentTranslation}</p>
			</div>
			{audioUrl && (
				<div className="text-center flex flex-col align-center">
					<h2>Recorded Audio</h2>
					<audio controls src={audioUrl}></audio>
				</div>
			)}
		</div>
	);
};

export default VoiceRecorderPage;
