import React, { createContext, useState, ReactNode, useContext } from "react";
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { Either } from "./utils";

const ENV = process.env.NODE_ENV
const OVERRIDE_AUTH = process.env.REACT_APP_OVERRIDE_AUTH === "true";
const INITIAL_AUTH = ENV === "development" && OVERRIDE_AUTH;

const AuthContext = createContext<IAuthContext | undefined>(undefined);


export const AuthProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isAuthenticated, setIsAuthenticated] = useState(INITIAL_AUTH);
	const [username, setUsername] = useState<string | null>(INITIAL_AUTH ? "unauthUser"  as string | null: null);

	const login = async (username: string, password: string) => {
		if (
			(username === "user" || username === "jeremy" || username === "drei") &&
			password === "password"
		) {
			setUsername(username);
			setIsAuthenticated(true);
			return { right: username };
		} else {
			return { left: new UserError() };
		}
	};

	const logout = () => {
		setIsAuthenticated(false);
		return Promise.resolve({right: null });
	};

	return (
		<AuthContext.Provider value={{ isAuthenticated, username, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

export const PrivateRoutes = () => {
	let { isAuthenticated, username: currentUser }  = useAuth();
	const { username: paramsUsername } = useParams<{ username: string }>();
	if (paramsUsername != null && paramsUsername != currentUser) {
		return <Navigate to="/login" />;
	}
    return (
        isAuthenticated ? <Outlet/> : <Navigate to='/login'/>
    )
}

interface IAuthContext {
	isAuthenticated: boolean;
	username: string | null;
	login:  (username: string, password: string) => Promise<AuthResponse<string>>;
	logout: () => Promise<AuthResponse<null>>;
}

class AuthError extends Error {};
class UserError extends AuthError {};

export type AuthResponse<t> = Either<AuthError, t>;
