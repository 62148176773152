import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/SignupPage.scss";

const SignupPage: React.FC = () => {
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [organization, setOrganization] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const navigate = useNavigate();

	const handleSignup = (e: React.FormEvent) => {
		e.preventDefault();
		console.log("Name:", name);
		console.log("Email:", email);
		console.log("Organization:", organization);
		console.log("Password:", password);
		navigate("/login");
	};

	return (
		<div className="flex-1 flex flex-col items-center justify-center">
			<form className="flex flex-col" onSubmit={handleSignup}>
				<label>Organization Name:</label>
				<input
					className="form-input"
					type="text"
					value={organization}
					onChange={(e) => setOrganization(e.target.value)}
				/>
				<label>Contact Name:</label>
				<input
					className="form-input"
					type="text"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<label>Email:</label>
				<input
					className="form-input"
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<label>Password:</label>
				<input
					className="form-input"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<button className="form-button self-center" type="submit">
					Sign Up
				</button>
			</form>
		</div>
	);
};

export default SignupPage;
