import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "../styles/Header.scss"; // Import CSS file for styling

interface HeaderProps {
	showLogoutButton?: boolean;
}

const Header: React.FC<HeaderProps> = () => {
	const { isAuthenticated, logout } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	const handleLogout = async () => {
		await logout();
		navigate("/login");
	};

	const handleHomeNavigation = () => {
		navigate("/"); // Navigate to the home page
	};

	// Determine if the current route is the home page
	const isHomePage = location.pathname === "/";

	return (
		<header className="header-container">
			<div className="header-title-container">
				<h1
					className={`header-title ${isHomePage ? "typing-animation" : ""}`}
					onClick={handleHomeNavigation}
				>
					MetafrasAI
				</h1>
			</div>
			{isAuthenticated && (
				<button onClick={handleLogout} className="logout-btn">
					Logout
				</button>
			)}
		</header>
	);
};

export default Header;
