import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { AuthProvider, PrivateRoutes } from "./AuthContext";
import { SocketProvider } from "./SocketContext";
import { RecorderProvider } from "./RecorderContext";
import { TranslationProvider } from "./TranslationContext";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Wrapper from "./components/Wrapper";
import VoiceRecorderPage from "./pages/VoiceRecorderPage";
import TranslationPage from "./pages/TranslationPage";
import SignupPage from "./pages/SignupPage";
import UserPage from "./pages/UserPage";
import "./styles/App.scss";

const App: React.FC = () => {
	return (
		<AuthProvider>
			<TranslationProvider>
				<SocketProvider>
					<RecorderProvider>
						<Router>
							<div className="App">
								<Wrapper>
									<Routes>
										<Route path="/" element={<HomePage />} />
										<Route path="/login" element={<LoginPage />} />
										<Route path="/signup" element={<SignupPage />} />
										<Route path="*" element={<Navigate to="/" />} />
										<Route element={<PrivateRoutes />}>
											<Route path="/user/:username" element={<UserPage />} />{" "}
											<Route
												path="/user/:username/:sessionId"
												element={<VoiceRecorderPage />}
											/>{" "}
										</Route>
										<Route
											path="/:sessionId"
											element={<TranslationPage />}
										/>{" "}
									</Routes>
								</Wrapper>
							</div>
						</Router>
					</RecorderProvider>
				</SocketProvider>
			</TranslationProvider>
		</AuthProvider>
	);
};

export default App;
