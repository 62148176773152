import React, { createContext, useState, ReactNode, useContext } from "react";
import { useAuth } from "./AuthContext";
import { Either } from "./utils";


const TranslationContext = createContext<ITranslationContext | undefined>(undefined);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [currentAudioSession, setCurrentAudioSession] = useState<string | null>(null);
    const { isAuthenticated } = useAuth();


	const createAudioSession = async () => {
        if (!isAuthenticated) {
            throw new Error("cannot create session when not authenticated");
        }
		if (currentAudioSession != null) {
			return { left: new TranslationAlreadyExistsError() };
		}
		const sessionId = Math.random().toString(36).substring(2, 6);
		setCurrentAudioSession(sessionId);
		return { right: sessionId }
	}
	const getAudioSession = async (sessionId?: string) => {
		return { right: sessionId ?? currentAudioSession };
	}

	return (
        <TranslationContext.Provider value={{ createAudioSession, getAudioSession }}>
			{children}
		</TranslationContext.Provider>
	);
};

export const useTranslation = () => {
	const context = useContext(TranslationContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

interface ITranslationContext {
	createAudioSession: () => Promise<TranslationResponse<string>>;
	getAudioSession: (sessionId?: string) => Promise<TranslationResponse<string | null>>;
}

export class TranslationError extends Error {};
export class TranslationAlreadyExistsError extends TranslationError {};

export type TranslationResponse<t> = Either<TranslationError, t>;
