import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";

import "../styles/HomePage.scss";

const HomePage: React.FC = () => {
	const { isAuthenticated, username } = useAuth();
	return (
			<div className="flex flex-1 flex-col justify-center items-center p-8 text-center">
				<h2 className="mt-0">Welcome to MetafrasAI</h2>
				<p>Your ultimate solution for AI-driven translations and more.</p>
				{ isAuthenticated ?
					<Link to={`/user/${username}`} className="btn">
						Get Started
					</Link>
					:
					<Link to="/login" className="btn">
						Get Started
					</Link>
				}
		</div>
	);
};

export default HomePage;
