import React from "react";
import "../styles/Footer.scss"; // Import CSS file for styling

const Footer: React.FC = () => {
	return (
		<footer className="footer">
			<p>&copy; 2024 MetafrasAI. All rights reserved.</p>
		</footer>
	);
};

export default Footer;
