import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../TranslationContext";

import "../styles/UserPage.scss";

const UserPage: React.FC = () => {
	const { username } = useParams<{ username: string }>();
	const navigate = useNavigate();
	const { createAudioSession } = useTranslation();

	const handleCreateSession = async () => {
		const { left: sessionError, right: session } = await createAudioSession()
		if (sessionError) {
			console.error("invalid response");
			return;
		}
		navigate(`/user/${username}/${session}`);
	};

	return (
		<div className="flex-1 flex flex-col items-center justify-center">
			<h1 className="welcome-message text-center">Welcome, {username}</h1>
			<div className="session-controls flex justify-center items-center cursor-pointer">
				<button className="form-button flex flex-col" onClick={handleCreateSession}>
					Create Session
				</button>
			</div>
		</div>
	);
};

export default UserPage;
