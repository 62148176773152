import React, { createContext, useState, ReactNode, useEffect, useRef, useCallback, useContext  } from "react";
import { MediaRecorder as MediaRecorderExt, IMediaRecorder, register, IBlobEvent } from 'extendable-media-recorder';
import { connect  } from 'extendable-media-recorder-wav-encoder';

const USE_MULTI_CALLBACK = process.env.REACT_APP_USE_MULTI_CALLBACK  === "true"
const RECORD_INTERVAL = parseInt(process.env.REACT_APP_TRANSLATION_INTERVAL || "6000", 10);

const RecorderContext = createContext<IRecorderContext | undefined>(undefined);


export const RecorderProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const mediaRecorderRef = useRef<IMediaRecorder | null>(null);
	const isRegisteredRef = useRef(false);
	const audioChunksRef = useRef<Blob[]>([]);

	const [isRecording, setIsRecording] = useState(false);
	const transpilePort = useRef<MessagePort | null>(null);
	const streamsRef = useRef<MediaStreamTrack[]>([]);
	const params = useRef<IRecorderParams>({});
	let isFirstRecord = true;
	let riffHeaders = new Blob([]);

	const onStopListeners = useRef<Array<(audioChunks: Blob[], event: Event) => void>>([]);
	const onStartListeners = useRef<Array<(event: Event) => void>>([]);
	const onDataAvailableListeners = useRef<Array<(event: IBlobEvent, riffHeaders: Blob) => void>>([]);

	useEffect(() => {
		return () => {
			mediaRecorderRef.current?.stop();
			onStopListeners.current = [];
			onStartListeners.current = [];
			onDataAvailableListeners.current = [];
		};
	}, [mediaRecorderRef]);


	const createMediaRecorder = async () => {
		if (!transpilePort.current) {
			const port = await connect();
			if (!isRegisteredRef.current) {
				await register(port);
				isRegisteredRef.current = true;
			}
			transpilePort.current = port;
		}
		if (transpilePort.current && !mediaRecorderRef.current) {
		    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			streamsRef.current = stream.getTracks();
			const recorder = new MediaRecorderExt(stream, { mimeType: "audio/wav" });
			recorder.onstart = (event) => {
				isFirstRecord = true;
				audioChunksRef.current = [];
				onStartListeners.current.forEach(callback => callback(event));
			};
			recorder.onstop = (event) => {
				onStopListeners.current.forEach(callback => callback(audioChunksRef.current, event));
			};
			recorder.ondataavailable = (event) => {
				audioChunksRef.current.push(event.data);
				if (params.current.createRiffHeaders) {
					if (isFirstRecord) {
						riffHeaders = event.data.slice(0, 44);
						isFirstRecord = false;
					} else if (!riffHeaders) {
						recorder.stop();
						throw new Error("failed to write binary header");
					}
				}
				onDataAvailableListeners.current.forEach(callback => callback(event, riffHeaders));
			};
			mediaRecorderRef.current = recorder;
		}
	};

	const startRecording = async () => {
		if (isRecording) {
			return;
		}
		await createMediaRecorder();
		setIsRecording(true);
		mediaRecorderRef.current?.start(params.current.interval || RECORD_INTERVAL);
	};
	const stopRecording = async () => {
		if (!transpilePort.current || !isRecording) {
			return;
		}
		streamsRef.current.forEach(stream => stream.stop());
		mediaRecorderRef.current?.stop();
		mediaRecorderRef.current = null;
		// if (transpilePort.current) {
		// 	await disconnect(transpilePort.current);
		// 	transpilePort.current = await connect();
		// 	// transpilePort.current = null;
		// }
		setIsRecording(false);
	};

	const onStart = useCallback((callback: (data: Event) => void) => {
		if (!USE_MULTI_CALLBACK) {
			onStartListeners.current = [callback];
			return;
		}
		onStartListeners.current.push(callback);
		return () => {
			onStartListeners.current = onStartListeners.current.filter((cb) => cb !== callback);
		};
	}, []);
	const onStop = useCallback((callback: (audioChunks: Blob[], event: Event) => void) => {
		if (!USE_MULTI_CALLBACK) {
			onStopListeners.current = [callback];
			return;
		}
		onStopListeners.current.push(callback);
		return () => {
			onStopListeners.current = onStopListeners.current.filter((cb) => cb !== callback);
		};
	}, []);
	const onDataAvailable = useCallback((callback: (event: IBlobEvent, riffHeaders: Blob) => void) => {
		if (!USE_MULTI_CALLBACK) {
			onDataAvailableListeners.current = [callback];
			return;
		}
		onDataAvailableListeners.current.push(callback);
		return () => {
			onDataAvailableListeners.current = onDataAvailableListeners.current.filter((cb) => cb !== callback);
		};
	}, []);

	const setParams = (parameters: IRecorderParams) => {
		params.current = parameters
	}


	return (
		<RecorderContext.Provider value={{
			startRecording,
			stopRecording,
			onStart,
			onStop,
			onDataAvailable,
			isRecording,
			setParams,
		}}>
			{children}
		</RecorderContext.Provider>
	);
};

export const useRecorder = (params: IRecorderParams) => {
	const context = useContext(RecorderContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	context.setParams(params);
	return context;
};

interface IRecorderContext {
	startRecording: () => void;
	stopRecording: () => void;
    onStart: (callback: (event: Event) => void) => void;
	onStop: (callback: (audioChunks: Blob[], event: Event) => void) => void;
    onDataAvailable: (callback: (event: IBlobEvent, riffHeaders: Blob) => void) => void;
	setParams: (params: IRecorderParams) => void;
	isRecording: boolean;
}

interface IRecorderParams {
	createRiffHeaders?: boolean,
	interval?: number
}

