import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useSocket } from "../SocketContext";

const TranslationPage: React.FC = () => {
	const [currentTranslation, setCurrentTranslation] = useState<string>("");
	const { sessionId } = useParams<{ sessionId: string }>();

	const socket = useSocket();
	useEffect(() => {
		socket.onConnect(() => {
			if (!sessionId) {
				console.error("no session id");
				return;
			}
			socket.joinTranslation(sessionId);
			console.debug("connected")
		});
		socket.onDisconnect(() => console.debug("disconnected"));
		socket.onTranslation(response => setCurrentTranslation(oldState => oldState + "\n" + response));
		socket.onError((origin, message) => console.error(`error from ${origin}: ${message}`));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket.onConnect, socket.onDisconnect, socket.onTranslation]);

	return (
		<div className="flex-1 flex flex-col items-center justify-center">
			<h2>Translation</h2>
			<p className="whitespace-pre-line">{currentTranslation}</p>
		</div>
	);
};

export default TranslationPage;
