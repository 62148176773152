import React, { createContext, ReactNode, useEffect, useRef, useCallback, useContext } from "react";
import { io, Socket } from "socket.io-client";

const ENV = process.env.NODE_ENV
const IS_PROD = ENV === "production";
const ALLOW_LIVE = IS_PROD || process.env.REACT_APP_ALLOW_LIVE === "true";
const ALLOW_LIVE_LEVEL = process.env.REACT_APP_ALLOW_LIVE_LEVEL;
const OVERRIDE_TRANSLATION = !(IS_PROD || process.env.REACT_APP_OVERRIDE_TRANSLATION  !== "true");
const USE_MULTI_CALLBACK = process.env.REACT_APP_USE_MULTI_CALLBACK  === "true"
const SOCKET_SERVER = process.env.REACT_APP_SOCKET_SERVER || "http://127.0.0.1:4000";

const SocketContext = createContext<ISocketContext | undefined>(undefined);


export const SocketProvider: React.FC<{ children: ReactNode }> = ({children}) => {
	const translationSocket = useRef<TranslationSocket.Connection | null>(null);
	const devSocket = useRef<DevSocket.Connection | null>(null);
	const audioSocket = useRef<AudioSocket.Connection | null>(null);
	const isConnectedRef = useRef<ISocketConnections>({audioSocket: false, devSocket: false, translationSocket: false});
	const onConnectListeners = useRef<TranslationSocket.onConnect[]>([]);
	const onDisconnectListeners = useRef<TranslationSocket.onDisconnect[]>([]);
	const onTranslationListeners = useRef<TranslationSocket.onTranslation[]>([]);
	const onTestResponseListeners = useRef<DevSocket.onTestResponse[]>([]);
	const onErrorListeners = useRef<DevSocket.onError[]>([]);
	const currentDevSession = useRef(Math.random().toString(36).substring(2, 6));

	useEffect(() => {
		if (!devSocket.current) {
			const ws: DevSocket.Connection = io(SOCKET_SERVER + "/dev", {
				auth: {
					version: "0.1.0"
				}
			});
			ws.on("connect", () => {
				isConnectedRef.current.devSocket = true;
				ws.emit("join", currentDevSession.current);
			});
			ws.on("disconnect", () => {
				isConnectedRef.current.devSocket = false;
			});
			ws.on("test_response", response => {
				onTestResponseListeners.current.forEach(callback => callback(response));
			});
			ws.on("log", response => {
				console.log("log: ", response);
			})
			devSocket.current = ws;
		}
		if (!audioSocket.current) {
			const ws: AudioSocket.Connection = io(SOCKET_SERVER + "/audio", {
				auth: {
					// TODO: this should be a real token
					token: "your_secret_token",
					dev_session: currentDevSession.current,
					version: "0.1.0"
				}
			});
			ws.on("connect", () => {
				isConnectedRef.current.audioSocket = true;
			});
			ws.on("disconnect", () => {
				isConnectedRef.current.audioSocket = false;
			});
			audioSocket.current = ws;
		}
		if (!translationSocket.current) {
			const ws: TranslationSocket.Connection = io(SOCKET_SERVER + "/translation", {
				auth: {
					dev_session: currentDevSession.current,
					version: "0.1.0"
				}
			});
			ws.on("connect", () => {
				isConnectedRef.current.translationSocket = true;
				onConnectListeners.current.forEach((callback) => callback());
			});
			ws.on("disconnect", () => {
				isConnectedRef.current.translationSocket = false;
				onDisconnectListeners.current.forEach((callback) => callback());
			});
			ws.on("translation", response => {
				onTranslationListeners.current.forEach(callback => callback(response));
			});
			translationSocket.current = ws;
		}
		if (!isConnectedRef.current.audioSocket) {
			audioSocket.current.connect();
		}
		if (!isConnectedRef.current.devSocket) {
			devSocket.current.connect();
		}
		if (!isConnectedRef.current.translationSocket) {
			translationSocket.current.connect();
		}
		return () => {
			translationSocket.current?.disconnect();
			audioSocket.current?.disconnect();
			devSocket.current?.disconnect();
			onConnectListeners.current = [];
			onDisconnectListeners.current = [];
			onTranslationListeners.current = [];
			onTestResponseListeners.current = [];
		};
	  }, [translationSocket]);

	const onConnect = useCallback((callback: () => void) => {
		if (!USE_MULTI_CALLBACK) {
			onConnectListeners.current = [callback];
			return;
		}
		onConnectListeners.current.push(callback);
		return () => {
			onConnectListeners.current = onConnectListeners.current.filter((cb) => cb !== callback);
		};
	}, []);
	const onDisconnect = useCallback((callback: () => void) => {
		if (!USE_MULTI_CALLBACK) {
			onDisconnectListeners.current = [callback];
			return;
		}
		onDisconnectListeners.current.push(callback);
		return () => {
			onDisconnectListeners.current = onDisconnectListeners.current.filter((cb) => cb !== callback);
		};
	}, []);
	const onTranslation = useCallback((callback: TranslationSocket.onTranslation) => {
		if (!USE_MULTI_CALLBACK) {
			onTranslationListeners.current = [callback];
			return;
		}
		onTranslationListeners.current.push(callback);
		return () => {
			onTranslationListeners.current = onTranslationListeners.current.filter((cb) => cb !== callback);
		};
	}, []);
	const onTestResponse = useCallback((callback: DevSocket.onTestResponse) => {
		if (!USE_MULTI_CALLBACK) {
			onTestResponseListeners.current = [callback];
			return;
		}
		onTestResponseListeners.current.push(callback);
		return () => {
			onTestResponseListeners.current = onTranslationListeners.current.filter((cb) => cb !== callback);
		};
	}, []);
	const onError = useCallback((callback: DevSocket.onError) => {
		if (!USE_MULTI_CALLBACK) {
			onErrorListeners.current = [callback];
			return;
		}
		onErrorListeners.current.push(callback);
		return () => {
			onErrorListeners.current = onErrorListeners.current.filter((cb) => cb !== callback);
		};
	}, []);
	const sendTranslation = useCallback((audio: Blob) => {
		if (!audioSocket.current || !isConnectedRef.current) {
			return;
		}
		if (!ALLOW_LIVE && !OVERRIDE_TRANSLATION) {
			switch (ALLOW_LIVE_LEVEL) {
				case "error":
					console.error("live tests are disabled for testing");
					return;
				case "warning":
					console.warn("this is a live test, please be aware that we are using credits");
					break;
				case "log":
					console.log("note that a live test is running");
					break;
				case "debug":
				default:
					console.debug("note that a live test is running");
					break;
			}
		}
		if (OVERRIDE_TRANSLATION) {
			audioSocket.current.emit("write_to_file", audio);
			return;
		}
		audioSocket.current.emit("translation", audio);
    }, []);
	const sendTranslationServerRiff = useCallback((audio: Blob) => {
		if (audioSocket.current && isConnectedRef.current) {
			audioSocket.current.emit("translation_riff", audio);
		}
    }, []);
	const sendWriteToFileTest = useCallback((audio: Blob) => {
		if (audioSocket.current && isConnectedRef.current) {
			audioSocket.current.emit("write_to_file", audio);
		}
    }, []);
	const joinAudio = useCallback((roomName: string) => {
		if (audioSocket.current && isConnectedRef.current) {
			audioSocket.current.emit("join", roomName);
		}
    }, []);
	const joinTranslation = useCallback((roomName: string) => {
		if (translationSocket.current && isConnectedRef.current) {
			translationSocket.current.emit("join", roomName);
		}
    }, []);
	const disconnect = useCallback(() => {
		if (translationSocket.current && isConnectedRef.current) {
			translationSocket.current.disconnect();
		}
		onConnectListeners.current = [];
		onDisconnectListeners.current = [];
		onTranslationListeners.current = [];
		onTestResponseListeners.current = [];
    }, []);

	return (
		<SocketContext.Provider value={{
			onConnect,
			onDisconnect,
			onTranslation,
			onTestResponse,
			onError,
			sendTranslation,
			sendTranslationServerRiff,
			sendWriteToFileTest,
			joinTranslation,
			joinAudio,
			disconnect,
		}}>
			{children}
		</SocketContext.Provider>
	);
};

export const useSocket = () => {
	const context = useContext(SocketContext);
	if (context === undefined) {
		throw new Error("useSocket must be used within an SocketProvider");
	}
	return context;
};

interface ISocketConnections {
	translationSocket: boolean;
	audioSocket: boolean;
	devSocket: boolean
}

interface ISocketContext {
	onConnect: (callback: TranslationSocket.onConnect) => void;
	onDisconnect: (callback: TranslationSocket.onDisconnect) => void;
	onTranslation: (callback: TranslationSocket.onTranslation) => void;
	onTestResponse: (callback: DevSocket.onTestResponse) => void;
	onError: (callback: DevSocket.onError) => void;
	sendTranslation: (audio: Blob) => void;
	sendTranslationServerRiff: (audio: Blob) => void;
	sendWriteToFileTest: (audio: Blob) => void;
	joinTranslation: (roomName: string) => void;
	joinAudio: (roomName: string) => void;
	disconnect: () => void;
}

namespace DevSocket {
	interface ServerToClientEvents {
		test_response: onTestResponse;
		log: onLog;
		error: onError;
	}
	interface ClientToServerEvents {
		join: (room: string) => void;
	}
	export type onTestResponse = (response: string) => void;
	export type onError = (origin: string, message: string) => void;
	export type onLog = (message: string) => void;
	export type Connection = Socket<ServerToClientEvents, ClientToServerEvents>;
}

namespace AudioSocket {
	interface ServerToClientEvents {
	}
	interface ClientToServerEvents {
		join: (room: string) => void;
		translation: (audio: Blob) => void;
		translation_riff: (audio: Blob) => void;
		write_to_file: (audio: Blob) => void;
	}

	export type onTranslation = (response: string) => void;

	export type Connection = Socket<ServerToClientEvents, ClientToServerEvents>;
}

namespace TranslationSocket {
 	export type onConnect = () => void;
 	export type onDisconnect = () => void;
	interface ServerToClientEvents {
		translation: onTranslation;
	}
	interface ClientToServerEvents {
		join: (room: string) => void;
		translation: (audio: Blob) => void;
		translation_riff: (audio: Blob) => void;
		write_to_file: (audio: Blob) => void;
	}

	export type onTranslation = (response: string) => void;
	export type onTestResponse = (response: string) => void;
	export type Connection = Socket<ServerToClientEvents, ClientToServerEvents>;
}
